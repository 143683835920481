import React from 'react';
import SVG from 'react-inlinesvg';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import arrow from '@assets/img/icons/arrow.svg';

function TextBlock({ data, pageContext, globalData }) {
  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const fadeIn = {
    visible: { opacity: 1, filter: 'blur(0)' },
    hidden: { opacity: 0, filter: 'blur(10px)' },
  };

  return (
    <AnimatePresence>
      <section className='text-section'>
        <motion.div
          ref={ref}
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeIn}
          transition={{ duration: 0.5 }}
          className='text-section-content'
        >
          {data.title && (
            <>
              <Heading
                text={data.title}
                tag='h2'
                className='text-section-title h1'
              />
              <div className='separator mb-20 mt-10 lg:w-3/4' />
            </>
          )}
          {data.text && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.75 }}
              className='text-section-text'
            >
              <Markdown type='longText'>{data.text}</Markdown>
            </motion.div>
          )}
          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`textSectionBtn-${i}`}
              trigger={button?.trigger}
              pageContext={pageContext}
              className={button.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection={button?.eventSection}
              gsEvent={button?.gsEvent}
              trackable
              ratingTimeBuffer={
                button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
              }
            >
              <span>{button?.text}</span>
            </Button>
          ))}
        </motion.div>
        <motion.div
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeIn}
          transition={{ duration: 0.5, delay: 1 }}
          className='text-section-more more-arrow'
        >
          <Markdown>{globalData.stringsAll?.cta?.learnMore || 'Learn More'}</Markdown>
          <SVG cacheRequests src={arrow} className='fill-secondary' />
        </motion.div>
        {data.background && (
          <Image
            media={data.background}
            alt=''
            className='section-background gatsby-absolute'
          />
        )}
      </section>
    </AnimatePresence>
  );
}

export default TextBlock;
