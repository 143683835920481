import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import Accordion from '../elements/Accordion/Accordion';
import AccordionRow from '../elements/Accordion/AccordionRow';

function FaqBlock({ data, pageContext, globalData }) {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const fadeIn = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const fadeInBlur = {
    visible: { opacity: 1, filter: 'blur(0)' },
    hidden: { opacity: 0, filter: 'blur(10px)' },
  };

  return (
    <AnimatePresence>
      <section className='faq-section'>
        <motion.div
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeInBlur}
          transition={{ duration: 0.5 }}
          className='faq-section-content'
        >
          {data.title && (
            <>
              <Heading
                text={data.title}
                tag='h2'
                className='faq-section-title h1'
              />
              <div className='separator mb-20 mt-10 lg:w-3/4' />
            </>
          )}
          {data.text && (
            <Markdown className='faq-section-text'>{data.text}</Markdown>
          )}
        </motion.div>

        {data.rows && (
          <motion.div
            ref={ref}
            initial='visible'
            animate={inView ? 'visible' : 'hidden'}
            exit='hidden'
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.25 }}
            className='faq-section-toggles mb-20'
          >
            <Accordion single>
              {data.rows.map((row, i) => (
                row.title && (
                  <AccordionRow
                    key={`faq-${i}`}
                    title={row.title}
                    pageContext={pageContext}
                    eventAction={`faq_${i + 1}`}
                    eventSection='faq'
                  >
                    <Markdown type='longText' className='accordion-text'>
                      {row.text}
                    </Markdown>
                  </AccordionRow>
                )
              ))}
            </Accordion>
          </motion.div>
        )}

        {data?.buttons?.map((button, i) => (
          <Button
            link={button.link}
            key={`faqSectionBtn-${i}`}
            trigger={button?.trigger}
            pageContext={pageContext}
            className={button.className}
            eventAction={button?.eventAction}
            eventPage={pageContext.pageDataId}
            eventSection={button?.eventSection}
            gsEvent={button?.gsEvent}
            trackable
            ratingTimeBuffer={
              button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
            }
          >
            <span>{button?.text}</span>
          </Button>
        ))}

        {data.background && (
          <Image
            media={data.background}
            alt=''
            className='section-background gatsby-absolute'
          />
        )}
      </section>
    </AnimatePresence>
  );
}

export default FaqBlock;
