import React, { useState, useRef, useEffect } from 'react'
import _uniqueId from 'lodash/uniqueId'

import trackLink from '@base/utils/helpers/tracking/track-link'

const AccordionRow = (props) => {
  const content = useRef(null)
  const [setHeight] = useState('0px')
  const componentClass = 'accordion-row'
  const [idButton] = useState(_uniqueId('accordion-'))
  const [idPanel] = useState(_uniqueId('accordion-panel-'))
  const defaultOpen = props.defaultOpen

  useEffect(() => {
    if (defaultOpen) {
      content.current.style.maxHeight = `${content.current.scrollHeight}px`
    }
  }, [])

  function toggleAccordion(e) {
    // Toggle Current Row
    let currentBtn = e.target
    let currentPanel = e.target.nextSibling
    if (currentBtn.classList.contains('active')) {
      closeAll()
      currentBtn.classList.remove('active')
      currentBtn.setAttribute('aria-expanded', 'false')
      currentPanel.style.maxHeight = '0px'
    } else {
      closeAll()
      currentBtn.classList.add('active')
      currentBtn.setAttribute('aria-expanded', 'true')
      currentPanel.style.maxHeight = `${content.current.scrollHeight}px`

      // Track Click
      const trackingData = {
        pageContext: props.pageContext,
        eventAction: props.eventAction,
        eventPage: props.pageContext.pageDataId || null,
        eventSection: props.eventSection,
      };
      trackLink(trackingData);
    }

    // Close all opened rows
    function closeAll() {
      let accordionWrapper = e.target.closest('.accordion')
      if (accordionWrapper && accordionWrapper.getAttribute('single') === 'true') {
        let rows = accordionWrapper.querySelectorAll('.accordion-row')
        for (var i = 0; i < rows.length; i++) {
          let btn = rows[i].querySelector('.accordion-button')
          let panel = rows[i].querySelector('.accordion-content')
          btn.classList.remove('active')
          btn.setAttribute('aria-expanded', 'false')
          panel.style.maxHeight = '0px'
        }
      }
    }
  }

  return (
    <div
      className={componentClass}
    >
      <button
        aria-expanded={defaultOpen ? true : false}
        aria-controls={idPanel}
        id={idButton}
        className={`accordion-button ${defaultOpen ? 'active' : ''}`}
        onClick={toggleAccordion}
      >
        <span className="accordion-title">
          {props.title}
        </span>
        <span className="accordion-icon">
          <svg viewBox="-122.9 121.1 105.9 61.9">
            <path d="M-63.2 180.3l43.5-43.5c1.7-1.7 2.7-4 2.7-6.5s-1-4.8-2.7-6.5c-1.7-1.7-4-2.7-6.5-2.7s-4.8 1-6.5 2.7L-69.9 161l-37.2-37.2c-1.7-1.7-4-2.7-6.5-2.7s-4.8 1-6.5 2.6c-1.9 1.8-2.8 4.2-2.8 6.6 0 2.3.9 4.6 2.6 6.5 11.4 11.5 41 41.2 43 43.3l.2.2c3.6 3.6 10.3 3.6 13.9 0z"></path>
          </svg>
        </span>
      </button>
      <div
        id={idPanel}
        ref={content}
        role="region"
        className="accordion-content"
        aria-labelledby={idButton}
        style={{ maxHeight: `${setHeight}` }}
      >
        <div className="accordion-inner-content">
          {props.children}
        </div>
      </div>
    </div>
  )
}
export default AccordionRow