import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Image from '@base/parts/image';

function TextLargeBlock({ data }) {
  return (
    <AnimatePresence>
      <section className='text-large-section'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.75 }}
          className='text-large-section-content container'
        >
          {data.title && (
            <>
              <Heading
                text={data.title}
                tag='h1'
                className='text-large-section-title h1'
              />
              <div className='separator mb-20 mt-5 lg:w-1/2' />
            </>
          )}
          {data.text && (
            <Markdown
              type='longText'
              className='text-large-section-text long-text'
            >
              {data.text}
            </Markdown>
          )}
        </motion.div>
        {data.background ? (
          <Image
            media={data.background}
            alt=''
            className='section-background gatsby-absolute'
          />
        ) : (
          <StaticImage
            src='../../assets/img/backgrounds/modal-background.jpg'
            alt=''
            placeholder='blurred'
            loading='lazy'
            className='section-background gatsby-absolute'
            role='presentation'
          />
        )}
      </section>
    </AnimatePresence>
  );
}

export default TextLargeBlock;
