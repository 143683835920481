import React, { useRef, useEffect } from 'react';
import SVG from 'react-inlinesvg';
import { motion, AnimatePresence, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Video } from '@bhvr/web-design-system';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';

import arrow from '@assets/img/icons/arrow.svg';
import Logo from '@elements/logo';

function HeroBlock({ data, pageContext, globalData }) {
  const prefersReducedMotion = useReducedMotion();

  // Play/Pause video when in/out of the viewport
  const refVideo = useRef(null);
  const [refVideoWrapper, inViewVideo] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (refVideo && !prefersReducedMotion) {
      if (inViewVideo) {
        refVideo.current?.play();
      } else {
        refVideo.current?.pause();
      }
    }
  }, [inViewVideo]);

  return (
    <AnimatePresence>
      <section ref={refVideoWrapper} className='hero-section'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className='hero-section-content'
        >
          <Logo globalData={globalData} use='alt' />
          <div className='separator my-10 lg:w-3/4' />
          {data.title && (
            <Heading
              text={data.title}
              tag='h1'
              className='hero-section-title'
            />
          )}
          {data.text && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5, delay: 0.75 }}
              className='hero-section-text mb-10'
            >
              <Markdown type='longText'>{data.text}</Markdown>
            </motion.div>
          )}
          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`heroSectionBtn-${i}`}
              trigger={button?.trigger}
              pageContext={pageContext}
              className={button.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection={button?.eventSection}
              gsEvent={button?.gsEvent}
              trackable
              ratingTimeBuffer={
                button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
              }
            >
              <span>{button?.text}</span>
            </Button>
          ))}
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.75 }}
          className='hero-section-more more-arrow'
        >
          <Markdown>{globalData.stringsAll?.cta?.scrollDown || 'Scroll Down'}</Markdown>
          <SVG cacheRequests src={arrow} className='fill-secondary' />
        </motion.div>
        {data.keyart && (
          <div className='hero-section-video '>
            <video
              ref={refVideo}
              playsInline
              autoPlay={!prefersReducedMotion}
              muted
              poster={data.keyartPoster.publicURL}
              loop
            >
              <source src={data.keyartWebM?.publicURL} loading='lazy' />
              <source src={data.keyart?.publicURL} loading='lazy' />
            </video>
          </div>
        )}
      </section>
    </AnimatePresence>
  );
}

export default HeroBlock;
