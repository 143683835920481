import React from 'react';
import SVG from 'react-inlinesvg';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import playBtn from '@img/icons/play.svg';

function VideoBlock({ data, pageContext, globalData }) {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const fadeIn = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const fadeInBlur = {
    visible: { opacity: 1, filter: 'blur(0)' },
    hidden: { opacity: 0, filter: 'blur(10px)' },
  };

  return (
    <AnimatePresence>
      <section className='video-section'>
        <motion.div
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeInBlur}
          transition={{ duration: 0.5 }}
          className='video-section-content mb-20'
        >
          {data.title && (
            <>
              <Heading
                text={data.title}
                tag='h2'
                className='video-section-title h1'
              />
              <div className='separator mb-20 mt-10 lg:w-3/4' />
            </>
          )}
          {data.text && (
            <Markdown type='longText' className='video-section-text'>
              {data.text}
            </Markdown>
          )}
        </motion.div>
        {data.video && (
          <motion.div
            ref={ref}
            initial='visible'
            animate={inView ? 'visible' : 'hidden'}
            exit='hidden'
            variants={fadeIn}
            transition={{ duration: 0.5, delay: 0.5 }}
            className='video-section-player mb-20'
          >
            <Image media={data.video.poster} alt='' className='w-full' />
            <Button
              id='videoBtn-player'
              link={data.video.youtubeID}
              trigger='videoModal'
              key='videoBtn-gameplay'
              pageContext={pageContext}
              overwrite
              className='video-button absolute left-0 top-0 flex h-full w-full cursor-pointer flex-col items-center justify-center'
              trackable
              eventAction='watch_trailer'
              eventPage={pageContext.pageDataId}
              eventSection={data.video?.eventSection}
              ratingTimeBuffer={2000}
              videoPlayer='youtube'
            >
              <SVG cacheRequests src={playBtn} className='play-icon' />
            </Button>
          </motion.div>
        )}
        {data?.buttons?.map((button, i) => (
          <Button
            link={button.link}
            key={`videoSectionBtn-${i}`}
            trigger={button?.trigger}
            pageContext={pageContext}
            className={button.className}
            eventAction={button?.eventAction}
            eventPage={pageContext.pageDataId}
            eventSection={button?.eventSection}
            gsEvent={button?.gsEvent}
            trackable
            ratingTimeBuffer={
              button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
            }
          >
            <span>{button?.text}</span>
          </Button>
        ))}
        {data.background && (
          <Image
            media={data.background}
            alt=''
            className='section-background gatsby-absolute'
          />
        )}
      </section>
    </AnimatePresence>
  );
}

export default VideoBlock;
