import React, { useEffect, useRef } from 'react'
import AccordionAccessibility from './Accessibility'

const Accordion = (props) => {
  const accordionRef = useRef(null)

  useEffect(() => {
    AccordionAccessibility(accordionRef.current)
  }, [])

  return (
    <div
      ref={accordionRef}
      className={`accordion ${props.className ? props.className : ''}`}
      id={props.id}
      single={props.single ? 'true' : 'false'}
    >
      {props.children}
    </div>
  )
}
export default Accordion