function AccordionAccessibility(accordionRef) {
  let accordion = accordionRef

  // Create the array of toggle elements for the accordion group
  let triggers = Array.prototype.slice.call(accordion.querySelectorAll('.accordion__button'))

  // Bind keyboard behaviors on the main accordion container
  accordion.addEventListener('keydown', function (event) {
    const target = event.target;
    const key = event.which.toString()

    // 33 = Page Up, 34 = Page Down
    const ctrlModifier = (event.ctrlKey && key.match(/33|34/))

    // Is this coming from an accordion header?
    if (target.classList.contains('accordion__button')) {
      // Up/ Down arrow and Control + Page Up/ Page Down keyboard operations
      // 38 = Up, 40 = Down
      if (key.match(/38|40/) || ctrlModifier) {
        const index = triggers.indexOf(target)
        const direction = (key.match(/34|40/)) ? 1 : -1
        const length = triggers.length
        const newIndex = (index + length + direction) % length
        triggers[newIndex].focus()
        event.preventDefault()
      } else if (key.match(/35|36/)) {
        // 35 = End, 36 = Home keyboard operations
        switch (key) {
          // Go to first accordion
          case '36':
            triggers[0].focus()
            break;
          // Go to last accordion
          case '35':
            triggers[triggers.length - 1].focus()
            break;
        }
        event.preventDefault()
      }
    }
  })

}
export default AccordionAccessibility