import React from 'react';
import SVG from 'react-inlinesvg';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';
import Image from '@base/parts/image';

import arrow from '@assets/img/icons/arrow.svg';

function TextImagesBlock({ data, pageContext, globalData }) {
  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const titleMinHeight = {
    es: 'md:min-h-[14.475rem]',
    fr: 'md:min-h-[11.36rem]',
    ja: 'md:min-h-[8.25rem]',
    pt: 'md:min-h-[11.36rem]',
  };

  const fadeIn = {
    visible: { opacity: 1, filter: 'blur(0)' },
    hidden: { opacity: 0, filter: 'blur(10px)' },
  };

  return (
    <AnimatePresence>
      <section className={`text-section`}>
        <motion.div
          ref={ref}
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeIn}
          transition={{ duration: 0.5 }}
          className='text-images-section-content'>
          <div className='text-images-section-content-container'>
            {data.title && (
              <>
                <Heading
                  text={data.title}
                  tag='h2'
                  className='text-section-title h1'
                />
                <div className='separator mb-10 mt-10 lg:w-3/4' />
              </>
            )}
            {data.subtitle && (
              <>
                <Heading
                  text={data.subtitle}
                  tag='h3'
                  className='text-section-title h1 mb-10'
                />
              </>
            )}
            {data.text && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.75 }}
                className='text-section-text'>
                <Markdown type='longText'>{data.text}</Markdown>
              </motion.div>
            )}
          </div>

          <div className='text-images-section-content-images'>
            {data?.rows?.map((row, i) => (
              <div className={`text-images-element mt-16 element-${row.type}`}>
                {row.background && (
                  <div className='element-background bg-black'>
                    <Image media={row.background} />
                  </div>
                )}
                <Image media={row.icon} className='element-icon' />

                <p
                  className={`element-title ${titleMinHeight[pageContext.locale] ? titleMinHeight[pageContext.locale] : 'min-h-[8.25rem]'} flex items-center pt-8 font-display text-lg uppercase tracking-wider text-white ${pageContext?.locale == 'en' ? 'max-w-[18rem]' : ''}`}>
                  {row.title}
                </p>
                <div className='element-image-container'>
                  <Image media={row.image} className='element-image w-full' />
                </div>

                <div
                  className={`mt-4 flex items-center bg-black bg-opacity-60 px-6 py-8 ${pageContext?.locale != 'en' ? 'md:min-h-[10.40rem]' : ''}`}>
                  <p className='font-body text-base text-white'>{row.text}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='w-full md:max-w-[57rem] lg:max-w-[75rem]'>
            <div className='separator mb-0 mt-20 lg:w-3/4 mx-auto' />
          </div>

          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`textImagesSectionBtn-${i}`}
              trigger={button?.trigger}
              pageContext={pageContext}
              className={button.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection={button?.eventSection}
              gsEvent={button?.gsEvent}
              trackable
              ratingTimeBuffer={
                button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
              }>
              <span>{button?.text}</span>
            </Button>
          ))}
        </motion.div>
        <motion.div
          initial='visible'
          animate={inView ? 'visible' : 'hidden'}
          exit='hidden'
          variants={fadeIn}
          transition={{ duration: 0.5, delay: 1 }}
          className='text-section-more more-arrow'>
          <Markdown>
            {globalData.stringsAll?.cta?.learnMore || 'Learn More'}
          </Markdown>
          <SVG cacheRequests src={arrow} className='fill-secondary' />
        </motion.div>
        {data.background && (
          <Image
            media={data.background}
            alt=''
            className='section-background gatsby-absolute'
          />
        )}
      </section>
    </AnimatePresence>
  );
}

export default TextImagesBlock;
